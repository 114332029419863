import React from "react"
import Layout from "../components/layout"
import Card from "../components/card"
import SEO from "../components/seo"

const ProductsPage = () => (
  <Layout>
    <SEO title="Products" keywords={[`brandsite`, `tempalate`, `gatsby`]} />
    <Card title={"Product A"} description={"Description of product A with additional information."}  actionLink={"/"} actionName={"Learn more"}/>
    <Card title={"Product B"} description={"Description of product B with additional information."}  actionLink={"/"} actionName={"View Products"}/>
  </Layout>
)

export default ProductsPage
